.react-international-phone-input {
  flex-grow: 1;
}

.react-international-phone-input.border-red-500 {
  border-color: #f56565;
}

.invalid .react-international-phone-country-selector .react-international-phone-country-selector-button {
  border-color: #f56565;
}
.react-international-phone-input-container {
  z-index: 10;
}

.react-international-phone-country-selector-dropdown {
  z-index: 20;
}

.react-international-phone-input-container {
  z-index: 3;
}