.image-card {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 auto;
  &--header {
    min-height: 100px;
    &--image {
      width: 100%;
      height: 200px;
      object-fit: cover;
      object-position: center;
      border-radius: 10px 10px 0 0;
    }
  }
  &--body {
    border-radius: 0 0 10px 10px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      &--title {
        @apply text-white;
      }
      &--description {
        @apply text-white;
      }
    }
  }
}
.actionsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// 'xsmall' | 'small' | 'default' | 'medium' | 'large'
.content-xsmall {
  padding: 0.5rem;
}
.content-small {
  @apply p-6 md:p-8;
}

.content-default {
  @apply p-8 md:p-12 pt-6 md:pt-6;
}

.white {
  @apply bg-white;
}

.blueDegreeBgToBottom {
  background: #0c3554;
  background: linear-gradient(to bottom, #031b2c 10%, #0c3554 100%);
}

.blueDegreeBgToTop {
  background: #0c3554;
  background: linear-gradient(to top, #031b2c 10%, #0c3554 100%);
}

.darkGrayDegreeBgToBottom {
  background: #4a4a4a;
  background: linear-gradient(to bottom, #272727 33%, #4b4b4b 100%);
}

.darkGrayDegreeBgToTop {
  background: #4a4a4a;
  background: linear-gradient(to top, #272727 33%, #4b4b4b 100%);
}
