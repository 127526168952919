.select {
  position: relative;
  text-align: left;
  z-index: 1;
  color: var(--blue-darkest);
  &--select-button {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--text-small);
    border-radius: var(--input-border-radius);
    &.invalid {
      border-color: var(--error);
    }
    > .chevron {
      display: inline-block;
    }
    &[aria-expanded='true'] {
      z-index: 2 !important;
    }
    &:not([aria-expanded='true']) {
      z-index: 1 !important;
    }
    &[aria-expanded='true'] > .chevron {
      transform: rotate(180deg);
      padding-right: 0.5em;
    }

    &:not([aria-expanded='true']) > .chevron {
      padding-left: 0.5em;
    }
    &:focus {
      border-color: var(--blue);
      border-radius: 2px;
    }
    &:not([aria-expanded='true']) + .select--select-dropdown {
      transform: scaleY(0);
    }
  }
  &--invalid {
    border-color: var(--error) !important;
  }

  &--select-dropdown {
    background: var(--white);
    color: var(--gray-darkest);
    min-width: 100%;
    position: absolute;
    z-index: 0;
    transform-origin: top center;
    transition: transform 0.1s;
    list-style: none;
    border: 1px solid var(--gray-medium);
    border-top: none;
    border-radius: 0 0 5px 5px;
    max-height: 200px;
    overflow-y: auto;
    padding: 0;
    li {
      padding: 0em;
      display: block;
      width: 100%;
      cursor: pointer;
      background: var(--white);
      font-size: var(--text-small);
    }
    li[aria-selected='true'] {
      background: var(--blue);
      color: var(--white);
    }
    li > label {
      width: 100%;
      display: flex;

      input[type='radio'] {
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
        padding: 0;
        margin: 0;
        & + span {
          padding: 1em 1em;
          display: block;
          width: 100%;
        }
        &.checked + span {
          font-weight: bold;
          width: 100%;
          background: var(--blue);
          color: var(--white);
          .visuallyHidden {
            width: 0;
            height: 0;
            display: block;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@media (prefers-reduced-motion) {
  .select {
    transition: none;
    &--select-dropdown {
      transition: none;
    }
  }
}
