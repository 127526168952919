button.btn,
a.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  border: 1.5px solid transparent;
  transition: all 0.5s;
  cursor: pointer;
  min-height: 40px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &--primary-light {
    @apply bg-blue-lightest text-black;
    &:hover {
      @apply bg-blue-lightest;
    }
  }
  &--primary {
    @apply text-white bg-blue;
    &:hover {
      color: #fff;
      background: var(--blue-darkest);
    }
  }

  &--primary-dark {
    @apply bg-blue-darkest text-white;
    * {
      @apply text-white;
    }
    &:hover {
      color: #fff;
      @apply bg-blue-light;
    }
  }

  &--secondary {
    @apply bg-green text-white;

    &:hover {
      @apply bg-blue-darkest;
    }
  }

  &--tertiary {
    @apply bg-gray-medium text-black;
    &:hover {
      @apply bg-blue-darkest text-white;
    }
  }

  &--primary-outline {
    @apply bg-transparent text-blue border-blue;
    &:hover {
      @apply bg-blue text-white;
    }
  }

  &--secondary-outline {
    @apply bg-transparent text-white border-green;
    &:hover {
      @apply bg-green text-white;
    }
  }

  &--tertiary-outline {
    @apply bg-transparent text-gray-medium border-gray-medium;
    &:hover {
      @apply bg-gray-medium text-black;
    }
  }
  &--white-outline {
    @apply bg-transparent text-white border-white;
    &:hover {
      @apply bg-blue-darkest text-white;
    }
  }

  &--keyboard {
    background: var(--white);
    color: var(--blue-darkest);
    border-radius: 0.125rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    padding: 0rem 0.75rem;
    height: 2rem;
    min-height: 0px;
    &:hover {
      background: var(--blue-darkest);
      color: var(--white);
    }
  }
  &--menu {
    min-height: unset;
    background: var(--white);
    color: var(--blue-darkest);
    border-radius: 0.125rem;
    &:hover {
      color: var(--blue);
    }
  }

  &--upload-file {
    border: 2px dashed var(--blue-dark);
    color: var(--blue-medium);
    padding: 0.5rem 0.75rem;
    &:hover {
      color: var(--blue-dark);
      border: 3px dashed var(--blue-dark);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
