.imageBanner {
  width: 100%;
}

.imageBannerLayer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2.25rem 0 2.5rem;
}

.container {
  width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.imageBannerContent {
  width: 70%;
  margin: 0 auto;
}

.small {
  min-height: 200px;
}

.medium {
  min-height: 500px;
}

.large {
  min-height: 400px;
}

.left {
  text-align: left;
  justify-content: left;
}

.center {
  text-align: center;
  justify-content: center;
}

.right {
  text-align: right;
  justify-content: right;
}

@media only screen and (max-width: 575px) {
  .imageBanner {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    max-width: 90%;
  }

  .imageBannerContent {
    width: 100%;
  }
}

@media only screen and (min-width: 575px) {
  .medium {
    min-height: 300px;
  }
}
