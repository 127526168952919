.input {
  padding: 0.8rem;
  width: 100%;
  border-radius: var(--input-border-radius);
  &:focus:not(.input--invalid) {
    border: 1px solid var(--blue);
    outline: none;
  }
  &--invalid {
    color: var(--error);
    border: 1px solid var(--error);
    &::placeholder {
      color: var(--error) 87;
    }
  }

  &--toggle-password {
    position: absolute;
    top: 0.6rem;
    right: 6.8px;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }

    &:hover {
      color: var(--gray-dark);
    }
  }
}
