.label {
  position: absolute;
  top: -8px;
  left: 10px;
  background-color: #fff;
  padding: 0 4px;
  font-size: 14px;
  color: var(--gray);
  display: flex;
  gap: 4px;
  z-index: 2;

  &--focused:not(.label--invalid) {
    color: var(--blue);
    z-index: 11;
  }
  &--invalid {
    color: var(--error);
  }
  &--required {
    color: var(--error);
  }
  &--hidden {
    display: none;
  }
  span {
    font-weight: 500;
  }
}
